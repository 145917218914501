import { useContext } from 'react';
import styled from 'styled-components';
import { Alignment, Navbar, Button } from '@blueprintjs/core';
import { Link, useRouteMatch } from 'react-router-dom';

import UserContext from './UserContext';

const StyledNavbarHeading = styled(Navbar.Heading)`
    font-style: italic;
    font-weight: 800;
    font-size: 2em;
`;

// as doesn't work with non-styled components so here's this
const LinkButton = (props) => {
    return (
        <Link
            className='bp5-button bp5-minimal'
            {...props}
        />
    );
};

const Navigation = () => {
    const [user, setUser] = useContext(UserContext);

    // the now playing page wants to be basically just plain text, so don't render if that's the route
    if (useRouteMatch('/nowplaying/:id') !== null) return null;

    const logout = async () => {
        const res = await fetch('/api/logout', {
            method: 'GET',
            credentials: 'include',
        });
        if (res.status === 200) {
            setUser(false);
        } else {
            // TODO: honestly how the fuck do u even handle logging out failing lmao
            // like shouldn't this still log the user out because the connection's fucked?
            // figure this one out later i guess
        }
    };

    return (
        <Navbar fixedToTop >
            <Navbar.Group align={Alignment.LEFT}>
                <StyledNavbarHeading>MediaShare+</StyledNavbarHeading>
                <Navbar.Divider />
                { user ? (
                    <>
                        <LinkButton
                            to="/home"
                        >
                            Home
                        </LinkButton>
                        <LinkButton
                            to={`/deck/${user.id}`}
                        >
                            My Deck
                        </LinkButton>
                        <LinkButton
                            to={`/nowplaying/${user.id}`}
                        >
                            Now Playing
                        </LinkButton>
                        <LinkButton
                            to="/settings"
                        >
                            Settings
                        </LinkButton>
                        <Button
                            onClick={logout}
                            minimal
                        >
                            Log Out
                        </Button>
                    </>
                ) : (
                    <>
                        <LinkButton
                            to="/login"
                        >
                            Log In
                        </LinkButton>
                        <LinkButton
                            to="/register"
                        >
                            Register
                        </LinkButton>
                    </>
                )}
            </Navbar.Group>
            <Navbar.Group align={Alignment.RIGHT}>
                v1.1
            </Navbar.Group>
        </Navbar>
    );
};

export default Navigation;
